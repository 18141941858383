import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MetaItemService } from '@mt-ng2/base-service';
import { ICategory } from '@model/interfaces/category';

@Injectable({
    providedIn: 'root',
})
export class CategoryService extends MetaItemService<ICategory> {
    constructor(public http: HttpClient) {
        super('CategoryService', 'Category', 'CategoryIds', '/model-categories', http);
    }
}
