import { Component, ElementRef, EventEmitter, Injector, Input, NgZone, OnInit, Output, Renderer2, ViewContainerRef } from '@angular/core';
import { VirtualTypeAheadComponent } from '@mt-ng2/type-ahead-control';
import { Key } from '@mt-ng2/type-ahead-control/lib/libraries/type-ahead.library';

@Component({
    selector: 'custom-virtual-type-ahead',
    templateUrl: './custom-type-ahead.component.html',
    styleUrls: ['./custom-type-ahead.component.css'],
})
export class CustomTypeAheadComponent extends VirtualTypeAheadComponent implements OnInit {
    @Output() innerControlBlurred: EventEmitter<any> = new EventEmitter<any>();
    @Output() innerControlCleared: EventEmitter<any> = new EventEmitter<any>();
    @Output() innerControlBackspace: EventEmitter<any> = new EventEmitter<any>();

    // This is used to allow the parent componet to clear the input whenever they want.
    @Input() clearEvent: EventEmitter<void>;

    constructor(injector: Injector, vcr: ViewContainerRef, renderer: Renderer2, ngZone: NgZone) {
        super(injector, vcr, renderer, ngZone);

        this.inputControl.valueChanges.subscribe((value: string) => {
            if (!value) {
                this.innerControlCleared.emit();
            }
        });

    }

    ngOnInit(): void {
        if (this.clearEvent) {
            this.clearEvent.subscribe(() => this.clearControl());
        }
    }

    clearControl(): void {
        this.inputControl.setValue('');
        this.innerControlCleared.emit();
    }

    backspaceCheck($event: KeyboardEvent): void {
        if ($event.key === 'Backspace') {
            this.innerControlBackspace.emit();
        }
    }

    onBlur(): void {
        if (!this.selected || (this.selected && this.inputElement && this.inputElement.nativeElement.value !== this.getValue(this.selected))) {
            this.handleSelection(null);
        }
        this.closePopup();
        this.innerControlBlurred.emit();
    }
}
