import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICustomer } from '@model/interfaces/customer';
import { IColumnSortedEvent, IItemSelectedEvent, ISelectionChangedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { forkJoin, Observable } from 'rxjs';
import { ViewAllUsedInventoryEntityListConfig } from './view-all-used-inventory.entity-list-config';
import { CommonService } from '../../admin-portal/common/services/common.service';
import { InventoryStatuses } from '@model/enums/inventory-statuses.enum';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IInventory } from '@model/interfaces/inventory';
import { IInventorySelection } from '@model/interfaces/custom/inventory-selection';
import { HttpResponse } from '@angular/common/http';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { InventoryTypes } from '@model/enums/inventory-types.enum';
import { InventoryService } from 'admin-portal/used-inventories/services/inventory.service';
import { DatePipe } from '@angular/common';
import { convertToEndOfDay, convertToStartOfDay } from '@common/Libraries/util';

interface IDateRange {
    startDate: Date;
    endDate: Date;
}

@Component({
    selector: 'app-view-all-used-inventory',
    templateUrl: './view-all-used-inventory.component.html',
})
export class ViewAllUsedInventoryComponent implements OnInit {
    @Input() selectedCustomer: ICustomer;
    @Output('itemSelected') itemSelected = new EventEmitter<IItemSelectedEvent>();
    @Output('selectionsMade') selectionsMade = new EventEmitter<IInventorySelection[]>();
    modalSelection: IInventorySelection[];

    entityListConfig: ViewAllUsedInventoryEntityListConfig;
    order: string;
    orderDirection: string;
    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];
    inventories: IInventory[];
    total: number;
    query = '';
    currentPage = 1;
    itemsPerPage = 10;
    selectedInventoryStatusIds: number[] = [];
    dateReceivedDateRange: IDateRange;
    datePaidThroughDateRange: IDateRange;
    paidThroughMonthly: string;

    constructor(private commonService: CommonService, private inventoryService: InventoryService, private datePipe: DatePipe) {}

    ngOnInit(): void {
        this.initEntityListConfig();
        forkJoin([this.commonService.getInventoryStatuses()]).subscribe(([inventoryStatuses]) => {
            inventoryStatuses = inventoryStatuses.filter(
                (is) => is.Id === InventoryStatuses.Picked || is.Id === InventoryStatuses.ReceivedIn || is.Id === InventoryStatuses.PendingApproval,
            );

            this.dynamicSearchFiltersConfig = [
                {
                    Searchbar: {
                        label: 'Search',
                        type: DynamicSearchFilterTypes.Searchbar,
                    },
                    InventoryStatuses: {
                        label: 'Inventory Status',
                        type: DynamicSearchFilterTypes.Select,
                        options: {
                            selectOptions: inventoryStatuses
                                .filter((is) => is.Id !== InventoryStatuses.Shipped)
                                .map((is) => new MtSearchFilterItem(is, false)),
                        },
                    },
                    DateReceived: {
                        label: 'Date Received',
                        type: DynamicSearchFilterTypes.Daterange,
                    },
                    DatePaidThrough: {
                        label: 'Paid Through',
                        type: DynamicSearchFilterTypes.Daterange,
                    },
                    PaidThroughMonthly: {
                        label: 'Paid Through Monthly',
                        type: DynamicSearchFilterTypes.Checkbox,
                    },
                },
            ];
        });
    }

    getInventories(): void {
        this.getInventoriesCall().subscribe((answer: HttpResponse<IInventory[]>) => {
            this.inventories = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getInventoriesCall(): Observable<HttpResponse<IInventory[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.inventoryService.get(searchparams);
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'InventoryTypeIds',
                valueArray: [InventoryTypes.Used],
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: [this.selectedCustomer.Id],
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'InventoryStatusIds',
                valueArray: this.selectedInventoryStatusIds,
            }),
        );

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'PaidThroughMonthly',
                value: this.paidThroughMonthly,
            }),
        );

        if (this.dateReceivedDateRange?.startDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DateReceivedStart',
                    value: convertToStartOfDay(this.dateReceivedDateRange.startDate).toISOString(),
                }),
            );
        }

        if (this.dateReceivedDateRange?.endDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DateReceivedEnd',
                    value: convertToEndOfDay(this.dateReceivedDateRange.endDate).toISOString(),
                }),
            );
        }

        if (this.datePaidThroughDateRange?.startDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DatePaidThroughStart',
                    value: convertToStartOfDay(this.datePaidThroughDateRange.startDate).toISOString(),
                }),
            );
        }

        if (this.datePaidThroughDateRange?.endDate) {
            _extraSearchParams.push(
                new ExtraSearchParams({
                    name: 'DatePaidThroughEnd',
                    value: convertToEndOfDay(this.datePaidThroughDateRange.endDate).toISOString(),
                }),
            );
        }

        return _extraSearchParams;
    }

    private initEntityListConfig(): void {
        this.entityListConfig = new ViewAllUsedInventoryEntityListConfig(this.datePipe);
        this.order = 'Customer.Name';
        this.orderDirection = this.entityListConfig.getDefaultSortDirection();
    }

    search(evt: DynamicSearchValues): void {
        this.currentPage = 1;
        this.query = evt.Searchbar as string;
        if (evt.InventoryStatuses) {
            this.selectedInventoryStatusIds = (evt.InventoryStatuses as IMetaItem[]).map((x) => x.Id);
        }
        if (evt.DateReceived) {
            this.dateReceivedDateRange = evt.DateReceived as IDateRange;
        }
        if (evt.DatePaidThrough) {
            this.datePaidThroughDateRange = evt.DatePaidThrough as IDateRange;
        }

        if (evt.PaidThroughMonthly === true) {
            this.paidThroughMonthly = 'true';
        } else {
            this.paidThroughMonthly = null;
        }

        this.getInventories();
    }

    inventorySelected(event: IItemSelectedEvent): void {
        this.itemSelected.emit(event);
    }

    selectionChanged(event: ISelectionChangedEvent): void {
        const selectedInventoryItems = event.selectedEntities as IInventory[];
        this.modalSelection = selectedInventoryItems.map((i) => {
            return {
                Inventory: i,
                InventoryModel: i.Model,
            };
        });
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getInventories();
    }

    confirmSelections(): void {
        this.selectionsMade.emit(this.modalSelection);
    }
}
