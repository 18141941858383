import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICustomer } from '@model/interfaces/customer';
import { IColumnSortedEvent, IItemSelectedEvent, ISelectionChangedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { forkJoin, Observable } from 'rxjs';
import { ViewCustomerAddressBooksEntityListConfig } from './view-customer-address-books.entity-list-config';
import { CommonService } from '../../admin-portal/common/services/common.service';
import { DynamicSearchFilterTypes, DynamicSearchValues, IDynamicSearchFilters, IMetaItem } from '@mt-ng2/dynamic-search-filters';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ICustomerAddressBook } from '@model/interfaces/customer-address-book';
import { HttpResponse } from '@angular/common/http';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { CustomerAddressBookService } from './services/customer-address-book.service'

interface IDateRange {
    startDate: Date;
    endDate: Date;
}

@Component({
    selector: 'app-view-customer-address-books',
    templateUrl: './view-customer-address-books.component.html',
})
export class ViewCustomerAddressBooksComponent implements OnInit {
    @Input() selectedCustomer: ICustomer;
    @Output('itemSelected') itemSelected = new EventEmitter<IItemSelectedEvent>();
    modalSelection: ICustomerAddressBook;

    entityListConfig: ViewCustomerAddressBooksEntityListConfig;
    order: string;
    orderDirection: string;
    dynamicSearchFiltersConfig: IDynamicSearchFilters = [];
    customerAddressBooks: ICustomerAddressBook[];
    total: number;
    query = '';
    currentPage = 1;
    itemsPerPage = 10;
    selectedCustomerAddressBookStatusIds: number[] = [];
    dateReceivedDateRange: IDateRange;
    datePaidThroughDateRange: IDateRange;
    paidThroughMonthly: string;

    constructor(private customerAddressBookService: CustomerAddressBookService) {}

    ngOnInit(): void {
        this.initEntityListConfig();
        this.getCustomerAddressBooks();
    }

    getCustomerAddressBooks(): void {
        this.getCustomerAddressBooksCall().subscribe((answer: HttpResponse<ICustomerAddressBook[]>) => {
            this.customerAddressBooks = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    getCustomerAddressBooksCall(): Observable<HttpResponse<ICustomerAddressBook[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.customerAddressBookService.get(searchparams);
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomerIds',
                valueArray: [this.selectedCustomer.Id],
            }),
        );

        return _extraSearchParams;
    }

    private initEntityListConfig(): void {
        this.entityListConfig = new ViewCustomerAddressBooksEntityListConfig();
        this.order = 'Id';
        this.orderDirection = 'desc';
    }

    search(evt: DynamicSearchValues): void {
        this.currentPage = 1;
        this.getCustomerAddressBooks();
    }

    customerAddressBookSelected(event: IItemSelectedEvent): void {
        this.itemSelected.emit(event);
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getCustomerAddressBooks();
    }
}
