import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { IInventoryModel } from '@model/interfaces/inventory-model';
import { Observable } from 'rxjs';
import { IInventoryModelAndQuantity } from '@model/interfaces/custom/inventory-model-and-quantity';
import { IOrderMachinePart } from '@model/interfaces/order-machine-part';

export const emptyInventoryModel: IInventoryModel = {
    CategoryId: 0,
    Id: 0,
    ItemCode: null,
    Name: null,
    Archived: false,
};

@Injectable({
    providedIn: 'root',
})
export class InventoryModelService extends BaseService<IInventoryModel> {
    constructor(public http: HttpClient) {
        super('/models', http, null, { entityName: 'Inventory Model' });
    }

    formatTitleText(inventoryModel: IInventoryModel): void {
        this.setTitle(`Inventory Model: ${inventoryModel.Name}`);
    }

    getEmptyInventoryModel(): IInventoryModel {
        return { ...emptyInventoryModel };
    }

    setCategory(categoryId: number, inventoryModelIds: number[]): Observable<void> {
        return this.http.put<void>(`/models/set-category/${categoryId}`, inventoryModelIds);
    }

    updateItemCode(inventoryModel: IInventoryModel): Observable<void> {
        return this.http.put<void>(`/models/update-item-code`, { Id: inventoryModel.Id, ItemCode: inventoryModel.ItemCode });
    }

    addFlag(flagId: number, inventoryModelIds: number[]): Observable<void> {
        return this.http.put<void>(`/models/flags/add/${flagId}`, inventoryModelIds);
    }

    removeFlag(flagId: number, inventoryModelIds: number[]): Observable<void> {
        return this.http.put<void>(`/models/flags/remove/${flagId}`, inventoryModelIds);
    }

    getModelIdByModelNumber(modelNumber: string): Observable<number> {
        return this.http.get<number>(`/models/model-id/model-number/${modelNumber}`);
    }

    getModelIdByItemCode(itemCode: string): Observable<number> {
        return this.http.get<number>(`/models/model-id/item-code/${itemCode}`);
    }

    checkDuplicateModelNumberSerialNumber(modelNumber: string, serialNumber: string): Observable<boolean> {
        return this.http.get<boolean>(`/models/is-duplicate-combo/${modelNumber}/${serialNumber}`);
    }

    getAllByCustomerWithQuantity(searchText?: string): Observable<IInventoryModelAndQuantity[]> {
        let params = new HttpParams();
        if (searchText) params = params.append('searchText', searchText);
        return this.http.get<IInventoryModelAndQuantity[]>(`/models/by-customer-with-qty`, {
            params: params,
        });
    }

    getReceivedInQuantityForCustomerById(inventoryModelId: number, customerId?: number): Observable<number> {
        let params = new HttpParams();
        if (customerId) params = params.append('customerId', customerId);
        return this.http.get<number>(`/models/received-in-qty-for-customer/${inventoryModelId}`, { params: params });
    }

    orderMachinePartsHaveAtLeastOneZeroReceivedInQuantityPart(orderMachineParts: IOrderMachinePart[], customerId?: number): Observable<boolean> {
        let params = new HttpParams();
        if (customerId) params = params.append('customerId', customerId);
        return this.http.post<boolean>(`/models/zero-qty-check`, orderMachineParts, { params: params });
    }

    checkForAtLeastOneZeroReceivedInQuantityPart(inventoryModelIds: number[], customerId?: number): Observable<boolean> {
        let params = new HttpParams();
        if (customerId) params = params.append('customerId', customerId);
        return this.http.post<boolean>(`/models/zero-qty-check-by-model-ids`, inventoryModelIds, { params: params });
    }

    /**
     *
     * @param inventoryModelIds
     * @param customerId
     * @returns The inventory model ids that have zero received in quantity parts for the given customer ( the current user's customer if no customerId provided)
     */
    getZeroReceivedInQuantityParts(inventoryModelIds: number[], customerId?: number): Observable<number[]> {
        let params = new HttpParams();
        if (customerId) params = params.append('customerId', customerId);
        return this.http.post<number[]>(`/models/zero-qty-received-in-parts`, inventoryModelIds, { params: params });
    }

    getByCustomerIdWithQuantity(searchText: string, customerId: number): Observable<IInventoryModelAndQuantity[]> {
        return this.http.get<IInventoryModelAndQuantity[]>(`/models/by-customer/${customerId}/with-qty`, {
            params: new HttpParams().append('searchText', searchText),
        });
    }

    getByCustomerWithQuantity(searchText: string, customerId: number, inventoryTypeId: number): Observable<IInventoryModelAndQuantity[]> {
        return this.http.get<IInventoryModelAndQuantity[]>(`/models/by-customer/${customerId}/inventory-type/${inventoryTypeId}/with-qty`, {
            params: new HttpParams().append('searchText', searchText),
        });
    }

    updateArchived(inventoryModel: IInventoryModel): Observable<any> {
        return this.http.put<void>(`/models/update-archived/${inventoryModel.Id}`, {});
    }
}
