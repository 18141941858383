<h4>Click on an item to add it to the machine.</h4>
<div>
    <mt-dynamic-search-filters [config]="dynamicSearchFiltersConfig" (searchValuesUpdated)="search($event)"></mt-dynamic-search-filters>
</div>

<mt-entity-list
    [entities]="inventoryModels"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getInventoryModels()"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
    (selectionChangedEvent)="selectionChanged($event)"
>
</mt-entity-list>

<button class="btn btn-primary" (click)="confirmSelections()">Confirm Selections</button>
