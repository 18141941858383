import { MtDate } from '@mt-ng2/date-module';

export const convertToStartOfDay = (date: Date): Date => {
    const returnDate = (new MtDate(date)).toMoment().clone().startOf('day').toDate();
    const tzOffset = returnDate.getTimezoneOffset();
    returnDate.setMinutes(returnDate.getMinutes() - tzOffset);
    return returnDate;
}

export const convertToEndOfDay = (date: Date): Date => {
    const returnDate = (new MtDate(date)).toMoment().clone().add('day').endOf('day').toDate();
    const tzOffset = returnDate.getTimezoneOffset();
    returnDate.setMinutes(returnDate.getMinutes() - tzOffset);
    return returnDate;
}
