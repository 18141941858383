import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { sortByProperty } from '@mt-ng2/common-functions';
import { IState } from '@model/interfaces/state';
import { ICountry } from '@model/interfaces/country';
import { IInventoryStatus } from '@model/interfaces/inventory-status';
import { IMetaItem } from '@mt-ng2/base-service';
import { IInventoryType } from '@model/interfaces/inventory-type';
import { IOrderStatus } from '@model/interfaces/order-status';
import { IPurchaseOrderStatus } from '@model/interfaces/purchase-order-status';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    private _states: IState[];
    private _countries: ICountry[];
    private _inventoryStatuses: IInventoryStatus[];
    private _inventoryTypes: IInventoryType[];
    private _orderStatuses: IOrderStatus[];
    private _orderCompanies: string[];
    private _purchaseOrderStatuses: IPurchaseOrderStatus[];

    constructor(private http: HttpClient) {}

    getStates(): Observable<IState[]> {
        if (!this._states) {
            return this.http.get<IState[]>('/options/states').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    this._states = answer;
                }),
            );
        } else {
            return of(this._states);
        }
    }

    getCountries(): Observable<ICountry[]> {
        if (!this._countries) {
            return this.http.get<ICountry[]>('/options/countries').pipe(
                tap((answer) => {
                    sortByProperty(answer, 'Name');
                    const indexOfUS = answer.findIndex((country) => country.CountryCode === 'US');
                    answer.splice(0, 0, answer.splice(indexOfUS, 1)[0]);
                    this._countries = answer;
                }),
            );
        } else {
            return of(this._countries);
        }
    }

    getOrderStatuses(): Observable<IOrderStatus[]> {
        if (!this._orderStatuses) {
            return this.http.get<IOrderStatus[]>('/options/order-statuses').pipe(
                tap((answer) => {
                    this._orderStatuses = answer;
                }),
            );
        } else {
            return of(this._orderStatuses);
        }
    }

    getOrderCompanies(): Observable<string[]> {
        if (!this._orderCompanies) {
            return this.http.get<string[]>('/options/order-companies').pipe(
                tap((answer) => {
                    this._orderCompanies = answer;
                }),
            );
        } else {
            return of(this._orderCompanies);
        }
    }

    getInventoryStatuses(): Observable<IInventoryStatus[]> {
        if (!this._inventoryStatuses) {
            return this.http.get<IInventoryStatus[]>('/options/inventory-statuses').pipe(
                tap((answer) => {
                    this._inventoryStatuses = answer;
                }),
            );
        } else {
            return of(this._inventoryStatuses);
        }
    }

    getInventoryTypes(): Observable<IInventoryType[]> {
        if (!this._inventoryTypes) {
            return this.http.get<IInventoryType[]>('/options/inventory-types').pipe(
                tap((answer) => {
                    this._inventoryTypes = answer;
                }),
            );
        } else {
            return of(this._inventoryTypes);
        }
    }

    getPurchaseOrderStatuses(): Observable<IPurchaseOrderStatus[]> {
        if (!this._purchaseOrderStatuses) {
            return this.http.get<IPurchaseOrderStatus[]>('/options/purchase-order-statuses').pipe(
                tap((answer) => {
                    this._purchaseOrderStatuses = answer;
                }),
            );
        } else {
            return of(this._purchaseOrderStatuses);
        }
    }

    /**
     * Converts an array of objects into an array of IMetaItems.
     * Useful for creating MtSearchFilterItems that contain only necessary information.
     *
     * @param entities
     * @param idProperty
     * @param nameProperty
     * @returns
     */
    convertObjectsToMetaItems(entities: unknown[], idProperty: string, nameProperty: string): IMetaItem[] {
        return entities.map((entity) => {
            return {
                Id: entity[idProperty],
                Name: entity[nameProperty],
            };
        });
    }

    public savePdf(data: string | BlobPart, outputFileName: string, dataIsBase64 = true): void {
        const outputData = dataIsBase64 ? this.base64ToByteArray(data as string) : data ;
        const blob = new Blob([outputData], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');

        downloadLink.style.display = 'none';
        downloadLink.href = (window.URL || (<any>window).webkitURL).createObjectURL(blob);
        downloadLink.download = outputFileName;

        document.body.appendChild(downloadLink);
        downloadLink.click();

        setTimeout(() => {
            document.body.appendChild(downloadLink);
        }, 100);
    }

    private base64ToByteArray(data: string): BlobPart {
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    }
}
