import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { ICustomerAddressBook } from '@model/interfaces/customer-address-book';
import { Observable } from 'rxjs';

export const emptyCustomerAddressBook: ICustomerAddressBook = {
    Company: '',
    Address: '',
    City: '',
    State: '',
    Zip: '',
    Id: 0,
    Archived: false,
};

@Injectable({
    providedIn: 'root',
})
export class CustomerAddressBookService extends BaseService<ICustomerAddressBook> {
    constructor(public http: HttpClient) {
        super('/customer-address-books', http, null, { entityName: 'Customer Address Books' });
    }

}
