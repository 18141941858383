import { Injectable, Injector } from "@angular/core";
import {Router, UrlTree } from "@angular/router";
import { AuthService } from "@mt-ng2/auth-module";
import { Observable } from "rxjs";

@Injectable()
export class IsCustomerContactLoadGuard  {

    public authService: AuthService;

    constructor(injector: Injector, private router: Router) {
        this.authService = injector.get<AuthService>(AuthService);
    }

    canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const isCustomerContact = this.authService.currentUser.getValue().CustomOptions.IsCustomerContact;
        if (!isCustomerContact) {
            return this.router.parseUrl('/home');
        } else {
            return true;
        }
    }
}
