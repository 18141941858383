import { Injectable, Injector } from "@angular/core";
import { Route, Router, UrlSegment, UrlTree } from "@angular/router";
import { AuthService } from "@mt-ng2/auth-module";
import { Observable } from "rxjs";

@Injectable()
export class IsAdminLoadGuard  {
    public authService: AuthService;

    constructor(injector: Injector, private router: Router) {
        this.authService = injector.get<AuthService>(AuthService);
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const isCustomerContact = this.authService.currentUser.getValue().CustomOptions.IsCustomerContact;
        if (isCustomerContact) {
            return this.router.parseUrl('/customer-contact-portal');
        } else {
            return true;
        }
    }
}
