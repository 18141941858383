import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '@mt-ng2/auth-module';
import { environment } from '@environment';

@Component({
    selector: 'app-root',
    template: `
        <div [hidden]="appReady === null">
            <div class="app-root-wrapper wrapper">
                <div>
                    <ng-progress></ng-progress>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <div [hidden]="appReady !== null" [style.position]="'relative'">
            <div class="pulse">
                <span></span>
                <img class="logo-img " src="{{ logoFull }}" alt="Logo" />
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'app';
    sidebarCollapsed: boolean;
    showNav: boolean;
    showFooter: boolean;
    appReady: boolean = null;
    logoFull = `${environment.assetsPath}logo-full.png`;
    subscriptions: Subscription = new Subscription();

    constructor(private authService: AuthService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.subscriptions.add(
            this.authService.appReady.subscribe((answer) => {
                this.appReady = answer;
                this.cdr.detectChanges();
                this.setHeaderSubmitHelpTitle();
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private setHeaderSubmitHelpTitle(): void {
        const headerSubmitHelp = document.querySelector('mt-header-submit-help div');
        if (!headerSubmitHelp)
            return;

        headerSubmitHelp.setAttribute('title', 'Need Help? Please call 201 997 8100');
    }
}
