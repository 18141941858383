<ng-container>
    <span>
        <input
            #inputElement
            type="text"
            class="form-control"
            [placeholder]="placeholder"
            autocorrect="off"
            autocomplete="off"
            autocapitalize="off"
            role="combobox"
            [formControl]="inputControl"
            (blur)="onBlur()"
            (keydown)="handleKeyDown($event); backspaceCheck($event)"
        />
    </span>
</ng-container>
