import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IManufacturer } from '@model/interfaces/manufacturer';

export const emptyManufacturer: IManufacturer = {
    Id: 0,
    Name: null,
    Sort: null,
};

@Injectable({
    providedIn: 'root',
})
export class ManufacturerService extends BaseService<IManufacturer> {
    constructor(public http: HttpClient) {
        super('/manufacturers', http);
    }

    getEmptyManufacturer(): IManufacturer {
        return { ...emptyManufacturer };
    }
}
