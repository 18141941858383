import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';

export class ViewCustomerAddressBooksEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessors: ['Company'],
                    name: 'Company',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['Address'],
                    name: 'Address',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['City'],
                    name: 'City',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['State'],
                    name: 'State',
                    columnClass: 'column-left-aligned',
                }),
                new EntityListColumn({
                    accessors: ['Zip'],
                    name: 'Zip',
                    columnClass: 'column-left-aligned',
                }),
            ],
        };
        super(listConfig);
    }
}
