// CommonAcrossPortalsModule.ts

import { NgModule } from '@angular/core';
import { ViewAllInventoryComponent } from './view-all-inventory/view-all-inventory';
import { ViewAllUsedInventoryComponent } from './view-all-used-inventory/view-all-used-inventory';
import { ViewCustomerAddressBooksComponent } from './view-customer-address-books/view-customer-address-books';
import { MtSearchBarControlModule } from '@mt-ng2/searchbar-control';
import { EntityListModule } from '@mt-ng2/entity-list-module';
import { MtSearchFilterSelectModule } from '@mt-ng2/search-filter-select-control';
import { DynamicSearchFiltersModule } from '@mt-ng2/dynamic-search-filters';
import { CustomTypeAheadComponent } from './custom-type-ahead/custom-type-ahead.component';
import { TypeAheadModule } from '@mt-ng2/type-ahead-control';
import { FormsModule } from '@angular/forms';
import { DynamicFormModule } from '@mt-ng2/dynamic-form';

@NgModule({
    imports: [
        MtSearchBarControlModule,
        TypeAheadModule,
        FormsModule,
        DynamicFormModule,
        EntityListModule,
        MtSearchFilterSelectModule,
        DynamicSearchFiltersModule,
    ],
    declarations: [ViewAllUsedInventoryComponent, ViewAllInventoryComponent, CustomTypeAheadComponent, ViewCustomerAddressBooksComponent],
    exports: [ViewAllUsedInventoryComponent, ViewAllInventoryComponent, CustomTypeAheadComponent, ViewCustomerAddressBooksComponent],
})
export class CommonAcrossPortalsModule {}
