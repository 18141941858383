export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    Customers_CanChangeStatus = 5,
    UserRoles = 4,
    Audit = 6,
    Inventories = 8,
    InventoryModels = 9,
    Orders = 10,
    InventoryModelsSearch = 11,
    PurchaseOrders = 12,
    Orders_AddOrder = 13,
    CustomerAddressBooks = 14,
}
