<h4>Click on an address to fill out the deliver to fields</h4>
<div>
    <mt-dynamic-search-filters [config]="dynamicSearchFiltersConfig" (searchValuesUpdated)="search($event)"></mt-dynamic-search-filters>
</div>

<mt-entity-list
    [entities]="customerAddressBooks"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getCustomerAddressBooks()"
    (itemSelectedEvent)="customerAddressBookSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
/>

