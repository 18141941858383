import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IInventory } from '@model/interfaces/inventory';
import { Observable } from 'rxjs';
import { IQuantityOfModelsExport } from '@model/interfaces/custom/quantity-of-models-export';
import { InventoryTypes } from '@model/enums/inventory-types.enum';

export const emptyInventory: IInventory = {
    DateCreated: null,
    Id: 0,
    InventoryStatusId: 0,
    InventoryTypeId: 0,
    ModelId: 0,
    SerialNumber: null,
    PaidThroughMonthly: false,
    Approved: false,
    Archived: false,
    ManufacturerId: 0,
    AssociatedPurchaseOrderId: null,
};

@Injectable({
    providedIn: 'root',
})
export class InventoryService extends BaseService<IInventory> {
    constructor(public http: HttpClient) {
        super('/inventories', http);
    }

    getEmptyInventory(): IInventory {
        return { ...emptyInventory };
    }

    updateCompany(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-company`, {Id: inventory.Id, Company: inventory.Company});
    }

    updateLocation(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-location`, {Id: inventory.Id, Location: inventory.Location});
    }

    updatePieceCount(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-piece-count`, {Id: inventory.Id, PieceCount: inventory.PieceCount});
    }

    updateFmdNumber(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-fmd-number`, {Id: inventory.Id, FmdNumber: inventory.FMDNumber});
    }

    updateSerialNumber(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-serial-number`, {Id: inventory.Id, SerialNumber: inventory.SerialNumber});
    }

    updateModelNumber(inventory: IInventory): Observable<void> {
        return this.http.put<void>(`/inventories/update-model-number`, {Id: inventory.Model.Id, ModelNumber: inventory.Model.Name});
    }

    updateArchived(inventory: IInventory): Observable<any> {
        return this.http.put<void>(`/inventories/update-archived/${inventory.Id}`, {});
    }

    getQuantityOfModels(customerIds: number[]): Observable<IQuantityOfModelsExport[]> {
        const customersString = customerIds && customerIds.length ? "&customerIds=" + customerIds.join(',') : '';
        return this.http.get<IQuantityOfModelsExport[]>(`/inventories/get-quantity-of-models?inventoryTypeId=${InventoryTypes.Used}${customersString}`);
    }

    approve(inventoryIds: number[]): Observable<void> {
        return this.http.put<void>(`/inventories/approve`, inventoryIds);
    }

    getBarCodePdf(id: number): Observable<string> {
        return this.http.get<string>(`/inventories/${id}/generate-barcode-pdf`, {});
    }
}
